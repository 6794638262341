import React, {useCallback, useEffect, useRef, useState} from 'react';
import * as MyActions from "./AdminPageApi";
import {addAlert} from "../../App/actions";
import {
    ButtonGroup,
    Button,
    Checkbox,
    Col,
    ControlLabel,
    FormControl,
    FormGroup,
    Grid,
    Nav,
    NavItem,
    OverlayTrigger,
    Radio,
    Row,
    Tab,
    Tabs,
    Tooltip,
    Well,
    InputGroup
} from "react-bootstrap";
import Helmet from "react-helmet";
import PageHeader from "../../../components/PageTitle";
import ActivityList from "../ActivityList";
import IssueList from "../IssueList/IssueList";
import AdminTransactions from "../Transactions/AdminTransactions";
import {bindActionCreators} from "redux";
import {geolocated} from "react-geolocated";
import {connect} from "react-redux";
import {debounce} from "throttle-debounce";
import ColorCheckbox from "../../Scheduler/ColorCheckbox";
import ClientTransactions from "../Transactions/ClientTransactions";
import TransferCustomer from "../TransferCustomer/TransferCustomer";
import Select from "react-select";
import {colors, isEmailValid, rolesDescriptions, select} from "../../../common/commonHandlers";
import Secured from "../../../common/Secured";
import {faCheck, faInfoCircle, faTabletAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UsersMap from "../UsersMap/UsersMap";
import {CirclePicker} from "react-color";
import Terms from "../Terms/Terms";
import Licences from "../Licences/Licences";
import UnfinishedRegistration from "../UnfinishedRegistration/UnfinishedRegistration";
import DevicesModal from '../../ClientsPage/tabs/Users/DevicesModal'
import {logout} from "../../LoginPage/actions"
import AppSettings from "../AppSettings/AppSettings";
import SubscriptionSettings from "../SubscriptionSettings/SubscriptionSettings";
import Emails from "../Emails/Emails";
import DeletedProposals from "../DeletedProposals/DeletedProposals";
import LeadsFilterModal from "../../LeadsPage/LeadsFilterModal";
import UsersMapFilters from "../UsersMap/UsersMapFilters";
import TextMessages from "../TextMessages/TextMessages";
import NewRegistration from "../NewRegistration/NewRegistration";
import {dateFormatter} from "../../../components/BootStrapTableCellFormatters";
import DisableAllUsersModal from "./DisableAllUsersModal";
import Backups from "../BackupsPage/Backups";
import config from "../../../config/environment";

const Actions = {...MyActions, addAlert};

const initialState = {
    isAdminPaymentActive: true,
    isClientPaymentActive: true,
    clientId: null,
    client: null,
    user: null,
    selectedUser: null,
    clientsList: [],
    users: [],
    coords: [],
    activityList: [],
    employees: [],
    stripeTransactions: [],
    roles: [],
}

const paymentState = {
    enableStripePayments: false,
    transactionFeeEnabled: false,
    enableRefunds: false,
    transactionFeeType: null,
    transactionFeeValue: null,
    stripeProcessingFee: null,
    customerFeeEnabled: false,
    customerFeeType: null,
    customerFeeValue: null
}

const newClientState = {
    id: 0,
    name: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone_number: '',
    email_address: '',
    web_url: '',
    entity_id: '',
};

const xSizeScreen = window.screen.width < 1600
const isMobile = window.screen.width <= 1024;

const NewAdminPage = ({clientData, userClientId, name, actions, roles, user, ...props}) => {
    const [state, setState] = useState(initialState);
    const [payment, setPayments] = useState(paymentState);
    const [filterText, setFilterText] = useState("")
    const [filteredUser, setFilteredUser] = useState("")
    const [key, setKey] = useState(1)
    const [clientsKey, setClientsKey] = useState(1)
    const [clientsTabsKey, setClientsTabsKey] = useState(1)
    const [userCapacity, setUserCapacity] = useState(null)
    const [showBtn, setShowBtn] = useState(false)
    const [usersList, setUsersList] = useState([])
    const [usersListFilterText, setUsersListFilterText] = useState('')
    const [selectedUserId, setSelectedUserId] = useState(null)
    const [showPicker, setShowPicker] = useState(false)
    const [nameErrorMsg, setNameErrorMsg] = useState('')
    const [emailErrorMsg, setEmailErrorMsg] = useState('')
    const [downloadNotificationsErrorMsg, setDownloadNotificationsErrorMsg] = useState('')
    const [userEmailError, setUserEmailError] = useState(null)
    const [isLoggedIn, setIsLoggedIn] = useState(true)
    const [showDevicesModal, setShowDevicedModal] = useState(false)
    const [devices, setDevices] = useState([])
    const [isUsersMapModalOpen, setIsUsersMapModalOpen] = useState(false)
    const [isDisableAllUsersModalOpen, setIsDisableAllUsersModalOpen] = useState(false)
    const [usersMapFilterResource, setUsersMapFilterResource] = useState({client_ids: []})
    const [usersMapFilterSelectedClients, setUsersMapFilterSelectedClients] = useState([])
    const [clientTranslating, setClientTranslating] = useState(false);
    const {clientsList, clientId, client, issueList, isClientPaymentActive, activityList, users} = state;

    useEffect(() => {
        const {fetchCustomerInfo} = actions;
        refreshClientsList()
        fetchCustomerInfo(userClientId)

        initialState.isAdminPaymentActive = true
        initialState.isClientPaymentActive = true
        initialState.client = null

        setState(initialState)
    }, []);

    useEffect(() => {
        if (filterText) {
            initialState.client = null
            setState(initialState)
        }
    }, [filterText])

    useEffect(() => {
        setSelectedUserId(null);
        if (key === 5) {
            fetchUsersList();
        }
    }, [usersListFilterText])

    useEffect(() => {
        if (showDevicesModal && selectedUserId) {
            loadDevices(selectedUserId)
        }
    }, [showDevicesModal])

    useEffect(() => {
        if (key === 5) {
            fetchUsersList();
        }
    }, [isLoggedIn, usersMapFilterResource, key])

    const isAdmin = () => {
        try {
            return (roles.length === 1 && roles[0] === 'it_engineering')
        } catch (e) {
            console.error('Admin check failed:', e);
            return false
        }
    }

    const loadDevices = (userId) => {
        const {loadDevices} = actions;

        loadDevices(userId, (result) => {
            setDevices(result)
        })
    }

    const refreshClientsList = () => {
        const {fetchClientsList} = actions;
        fetchClientsList(clients => {
            initialState.clientsList = clients
            initialState.client = null
            setState(initialState)
        });
    }

    const refreshUsersList = () => {
        fetchUsersList();
        setSelectedUserId(null);
    }

    const fetchUsersList = () => {
        actions.fetchAllUsers(isLoggedIn, {client_ids: usersMapFilterResource.client_ids,
        user: usersListFilterText}, (users) => {
            setUsersList(users)
        })
    }

    const translateClientData = () => {
        setClientTranslating(true);
        actions.translateClientData(clientId, () => {
           setClientTranslating(false);
        })
    }

    const selectUser = (u) => {
        setSelectedUserId(u)
    }

    const changeFilterText = (txt) => setFilterText(txt)

    const changeUserFilter = (txt) => setFilteredUser(txt)

    const changeUserListFilterText = useCallback(
        debounce(500, false, txt => setUsersListFilterText(txt)),
        [],
    );

    const filterRecord = record => {
        if (record && record.name) {
            return record.name.toLowerCase().indexOf(filterText.toLowerCase()) > -1
        }
    }

    const userFilterRecord = record => record.email.toLowerCase().indexOf(filteredUser.toLowerCase()) > -1

    const updateUserAttr = e => {
        const {user} = state
        setState({...initialState, user: {...user, [e.target.name]: e.target.value}})
    }

    const checkUserAttr = e => {
        const {user} = state
        setState({...initialState, user: {...user, [e.target.name]: e.target.checked}})
    };

    const deleteUser = (userId, clientId) => {
        actions.deleteUser(userId, clientId ? clientId : state.clientId, () => {
            actions.fetchUsers(clientId ? clientId : state.clientId, users => {
                initialState.users = users
                setState(initialState)
            })
        })
        initialState.user = null
        setState(initialState)
    }

    const handleSelectedRole = roleId => e => {
        const {user} = state;
        if (e.target.checked) {
            setState({...initialState, user: {...user, roles: [...user.roles, roleId]}})
        } else {
            setState({...initialState, user: {...user, roles: user.roles.filter(r => r !== roleId)}})
        }
    }

    const validateEmail = value => {
        const isValid = !!value && !!value.trim().length && isEmailValid(value);
        const errorChanged = !!isValid !== !userEmailError;
        if(errorChanged){
            setUserEmailError(isValid ? null : "Invalid email address");
        }
    }

    const saveUser = async e => {
        const {saveUserInfo, fetchUsers} = actions;
        const {user, clientId} = state;
        e.preventDefault();
        if(!userEmailError){
            if (client.disabled) {
                user.disabled = true
            }
            saveUserInfo(user, clientId, () => fetchUsers(clientId, users => {
                initialState.users = users
                setState(initialState)
            }))
        }
    };

    const handleUserSelect = (e) => {
        e.password_confirmation = ''
        e.password = ''
        initialState.selectedUser = e.id ? initialState.selectedUser : null
        setState({
            ...initialState,
            user: Object.assign({}, e),
            employees: e.person_id ? [{person_id: e.person_id, name: `${e.first_name} ${e.last_name}`}] : []
        })
    }

    const handleDisableAllUsers = () => {
        const {disableAllUsers, fetchUsers, fetchCustomerInfo, fetchClientsList} = actions;
        disableAllUsers({
            client_id: client.id
        }, () => {
            fetchClientsList(clients => {
                initialState.clientsList = clients
                setState(initialState)
            });
            loadClient(clientId)
        })
    }

    const saveAll = async e => {
        const {saveOrCreateClient, fetchClientsList} = actions;
        const {client} = state;
        let invalidEmail;
        let invalidNotificationEmail;
        e.preventDefault();

        if (client.email_address && client.email_address.length > 0) {
            invalidEmail = !client.email_address.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        }

        if (state.client.download_notifications && client.download_notifications_recipients?.length > 0) {
            invalidNotificationEmail = !client.download_notifications_recipients.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        }

        if (invalidEmail) {
            setEmailErrorMsg("Invalid email value!")
        } else if (invalidNotificationEmail || (state.client.download_notifications && !client.download_notifications_recipients)) {
            setDownloadNotificationsErrorMsg("Invalid email value!")
        } else {
            saveOrCreateClient(client, newClient => {
                setState({...initialState, clientsList: [...clientsList, newClient]})
                fetchClientsList(clients => {
                    initialState.clientsList = clients
                    setState(initialState)
                    client.disabled ? setClientsTabsKey(2) : setClientsTabsKey(1)
                });
                editSelectedClient(clientId)
            })
        }
    };

    const validate = e => {
        const {clientsList, clientId} = state;
        const validatedFields = ['name', 'email_address']

        if (clientsList && clientsList.length > 0 && validatedFields.includes(e.target.name)) {
            const fieldValue = clientsList.find(c => (c[e.target.name].trim() === e.target.value.trim()) && c.id !== clientId)

            if (e.target.name === 'name') {
                if (fieldValue && e.target.value.length > 0) {
                    setNameErrorMsg(`Client with this name already exists!`)
                } else {
                    setNameErrorMsg('')
                }
            } else if (e.target.name === 'email_address') {
                if (fieldValue && e.target.value.length > 0) {
                    setEmailErrorMsg(`Client with this email address already exists!`)
                } else {
                    setEmailErrorMsg('')
                }
            }
        }
    }

    const updateClientAttr = e => {
        validate(e)
        setState({...initialState, client: {...client, [e.target.name]: e.target.value}})
    }

    const updateClientColor = color => setState({
        ...initialState,
        client: {...client, ["embedded_background_color"]: color}
    })

    const getCustomerPaymentsInfo = () => {
        actions.fetchCustomerInfo(clientId, client => {
            const {enable_payments, transaction_fee, refunds_enabled, stripe_processing_fee, customer_fee} = client;

            const transactionFeeType = transaction_fee && (transaction_fee.includes('%') ? 'Percentage' : transaction_fee.includes('$') ? "Amount" : null);
            const transactionFeeValue = transaction_fee && (transaction_fee.includes('%') ? +transaction_fee.slice(0, -1) : transaction_fee.includes('$') ? +transaction_fee.substr(1) : null);
            const customerFeeType = customer_fee && (customer_fee.includes('%') ? 'Percentage' : customer_fee.includes('$') ? "Amount" : null);
            const customerFeeValue = customer_fee && (customer_fee.includes('%') ? +customer_fee.slice(0, -1) : customer_fee.includes('$') ? +customer_fee.substr(1) : null);

            setPayments({
                enableStripePayments: enable_payments,
                transactionFeeEnabled: !!transaction_fee,
                enableRefunds: refunds_enabled,
                transactionFeeType: transactionFeeType,
                transactionFeeValue: transactionFeeValue,
                stripeProcessingFee: !!stripe_processing_fee,
                customerFeeEnabled: !!customer_fee,
                customerFeeType: customerFeeType,
                customerFeeValue: customerFeeValue
            });
        });
    }

    const editSelectedClient = (clientId) => {
        if (clientId === 0) {
            initialState.client = newClientState
            initialState.clientId = clientId
            initialState.user = null
            setState({...initialState})
            setUserCapacity(null)
        } else if (clientId !== 0 && clientId !== null) {
            loadClient(clientId)
        }
    }

    const loadClient = (clientId) => {
        const {fetchCustomerInfo, fetchUsers, fetchRoles, fetchEmployees} = actions;

        fetchCustomerInfo(clientId, (client) => {
            setUserCapacity(client.user_capacity)
            initialState.client = client

            const {enable_payments, transaction_fee, refunds_enabled, stripe_processing_fee, customer_fee} = client;

            const transactionFeeType = transaction_fee && (transaction_fee.includes('%') ? 'Percentage' : transaction_fee.includes('$') ? "Amount" : null);
            const transactionFeeValue = transaction_fee && (transaction_fee.includes('%') ? +transaction_fee.slice(0, -1) : transaction_fee.includes('$') ? +transaction_fee.substr(1) : null);
            const customerFeeType = customer_fee && (customer_fee.includes('%') ? 'Percentage' : customer_fee.includes('$') ? "Amount" : null);
            const customerFeeValue = customer_fee && (customer_fee.includes('%') ? +customer_fee.slice(0, -1) : customer_fee.includes('$') ? +customer_fee.substr(1) : null);

            setPayments({
                enableStripePayments: enable_payments,
                transactionFeeEnabled: !!transaction_fee,
                enableRefunds: refunds_enabled,
                transactionFeeType: transactionFeeType,
                transactionFeeValue: transactionFeeValue,
                stripeProcessingFee: !!stripe_processing_fee,
                customerFeeEnabled: !!customer_fee,
                customerFeeType: customerFeeType,
                customerFeeValue: customerFeeValue
            });
            setState({...initialState, client: client})
        })

        initialState.clientId = clientId
        initialState.user = null

        fetchUsers(clientId, users => {
            initialState.users = users
            setState({...initialState, users: users})
        })
        fetchRoles(roles => {
            initialState.roles = roles
            setState({...initialState, roles: roles})
        })
    }

    const updatePayments = () => {
        let params = {};
        const {
            enableStripePayments,
            enableRefunds,
            transactionFeeEnabled,
            transactionFeeType,
            transactionFeeValue,
            customerFeeEnabled,
            customerFeeType,
            customerFeeValue,
            stripeProcessingFee
        } = payment;
        const {client} = state;
        const {updatePayments} = actions;

        if (enableStripePayments) {
            params.enableStripePayments = enableStripePayments;
        } else {
            params.transactionFeeType = null;
        }
        if (enableRefunds) {
            params.enableRefunds = enableRefunds;
        } else {
            params.enableRefunds = false;
        }
        if (transactionFeeEnabled) {
            params.transactionFeeType = transactionFeeType;
            params.transactionFeeValue = transactionFeeValue;
        } else {
            params.transactionFeeType = null;
            params.transactionFeeValue = null;
        }
        if (customerFeeEnabled) {
            params.customerFeeType = customerFeeType;
            params.customerFeeValue = customerFeeValue;
        } else {
            params.customerFeeType = null;
            params.customerFeeValue = null;
        }
        if (stripeProcessingFee) {
            params.stripeProcessingFee = stripeProcessingFee
        } else {
            params.stripeProcessingFee = null
        }

        params.id = client.id;
        updatePayments(params, client => {
            getCustomerPaymentsInfo(client.id)
        })
    };

    const changeUserCapacity = () => {
        const {changeUserCapacity} = actions;
        const {clientId} = state;

        changeUserCapacity(userCapacity, clientId)
    }

    const handleSelect = (key) => setKey(key)

    const handleClientsSelect = (key) => setClientsKey(key)

    const delayedSaveUserCapacity = debounce(1000, changeUserCapacity);

    const deleteDevice = (device) => {
        const {deleteDevice} = actions;

        deleteDevice(device, () => {
            if (navigator.userAgent === device.device) {
                logout();
            }

            loadDevices(selectedUserId)
        });
    };

    const renderTooltip = () => (
        <Tooltip id="tooltip">
            <div>Show user devices</div>
        </Tooltip>
    );

    const renderUserEditor = () => {
        const {employees, coords, user, roles} = state;
        const {impersonate, refreshToken, fetchClientInfo} = actions;

        let employeesOptions = []
        if (employees && employees.length > 0) {
            employeesOptions = employees.map(e => ({label: e.name ? e.name : e.label, value: e.person_id ? e.person_id : e.value}))
        } else if (initialState?.selectedUser) {
            employeesOptions = [{value: initialState?.selectedUser?.person_id, label: `${initialState?.selectedUser?.first_name} ${initialState?.selectedUser?.last_name}`}]
        }

        if (user) {
            const userName = user.first_name ? `${user.first_name} ${user.last_name}` : 'New user';

            return (
                <form onSubmit={e => saveUser(e)}>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={6}>
                                    <h4 className="text-primary">{userName}</h4>
                                    <hr/>
                                </Col>
                                <Col md={6}>
                                    <Select className="Select" classNamePrefix="select"
                                            placeholder="Employee"
                                            simpleValue={true}
                                            autoComplete="new-employee"
                                            value={select(employeesOptions, user.person_id)}
                                            onInputChange={(e) => {
                                                if (e?.length > 0) {
                                                    actions.loadNotSelectedEmployee(e, state?.clientId, employees => {
                                                        initialState.employees = employees
                                                        setState({...state, employees: employees})
                                                    })
                                                }
                                            }}
                                            onChange={selected => setState({
                                                ...initialState,
                                                user: {...user, person_id: selected.value}
                                            })}
                                            options={employeesOptions}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Email <span style={{color: '#d9230f'}}>*</span>
                                        </ControlLabel>
                                        <FormControl
                                            className={!!userEmailError ? "input-invalid" : null}
                                            name="email"
                                            placeholder="email"
                                            autoComplete="new-email"
                                            onChange={(e) => {
                                                validateEmail(e.target.value);
                                                updateUserAttr(e)}}
                                            value={user.email}
                                        />
                                        {!!userEmailError && <div className='error-message'>{userEmailError}</div>}
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    Roles
                                    {user && roles && roles.map(r => {
                                            const checkbox = (
                                                <FormGroup key={r.id}>
                                                    <Checkbox
                                                        name="role_name"
                                                        onChange={handleSelectedRole(r.id)}
                                                        inline
                                                        checked={user.roles.includes(r.id)}
                                                    >
                                                        {" "}
                                                        <OverlayTrigger placement="top" overlay={<Tooltip id="user_tooltip">
                                                            <div>{rolesDescriptions[r.role_name]}</div>
                                                        </Tooltip>}>
                                                            <div>
                                                                {r.role_name}
                                                            </div>
                                                        </OverlayTrigger>
                                                    </Checkbox></FormGroup>);
                                            return r.role_name === 'it_engineering' ? (
                                                <Secured key={r.id} roles="it_engineering">{checkbox}</Secured>) : checkbox;
                                        }
                                    )}
                                    GPS requirements
                                    <ColorCheckbox className='no-left-padding top10'
                                                   label='GPS tracking disabled'
                                                   value={state.user.optional_geolocation}
                                                   onChange={value => setState({
                                                       ...initialState,
                                                       user: {...user, optional_geolocation: value}
                                                   })}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            New Password
                                        </ControlLabel>
                                        <FormControl
                                            name="password"
                                            type="password"
                                            autoComplete="new-password"
                                            placeholder="password"
                                            value={user.password}
                                            onChange={updateUserAttr}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        <ControlLabel>
                                            Password Confirmation
                                        </ControlLabel>
                                        <FormControl
                                            name="password_confirmation"
                                            type="password"
                                            autoComplete="new-password"
                                            placeholder="password confirmation"
                                            value={user.password_confirmation}
                                            onChange={updateUserAttr}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormGroup>
                                        {user.id &&
                                        <Checkbox
                                            name="disabled"
                                            onChange={checkUserAttr}
                                            inline
                                            checked={user.disabled}
                                            disabled={client.disabled}
                                            title={client.disabled ? "Client is disabled, enable client to edit" : null}
                                        >
                                            disabled
                                        </Checkbox>
                                        }
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {user.id && <div className="center-block imper_token">
                                <Button onClick={() => deleteUser(user.id)} className="pull-left">
                                    Delete User
                                </Button>
                                <Button
                                    disabled={client.disabled || user.disabled}
                                    title={client.disabled ? "Client is disabled, enable client to impersonate" :
                                        (user.disabled ? "User is disabled, enable user to impersonate" : null)}
                                    onClick={() => impersonate(user.id, state.isGeolocationAvailable && state.isGeolocationEnabled && coords && coords, () => fetchClientInfo(client.id))}>
                                    Impersonate
                                </Button>
                                <Button onClick={() => refreshToken(user.id)}>
                                    Refresh Token
                                </Button>
                            </div>}
                            <Button bsStyle="info" className={`pull-right ${isMobile ? "mt-10" : ""}`} type="submit">
                                Save User
                            </Button>
                        </Col>
                    </Row>
                </form>
            )
        }
    }

    const renderClientEditor = () => {
        const {selectedUser} = state;
        const invalidFieldValue = (!client.name || !client.email_address) || (nameErrorMsg || emailErrorMsg || downloadNotificationsErrorMsg)
        return (
            <Tabs defaultActiveKey={1}
                  animation={false}
                  id="noanim-tab-example"
                  onSelect={handleClientsSelect}
                  activeKey={clientsKey}>
                <Tab eventKey={1} title="Organization Info">
                    {clientsKey == 1 && <Grid fluid className="top25">
                        <Row>
                            <Col sm={12}>
                                <form onSubmit={saveAll}>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Company Name*
                                                </ControlLabel>
                                                <FormControl
                                                    name="name"
                                                    placeholder="Company Name"
                                                    onChange={updateClientAttr}
                                                    value={client.name}
                                                />
                                                <span className='font10 colorRed'>{nameErrorMsg}</span>
                                            </FormGroup>
                                        </Col>

                                        <Col md={3}>
                                            <br/>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Client GPS requirements
                                                </ControlLabel>
                                                <ColorCheckbox className='no-left-padding'
                                                               label='GPS tracking disabled'
                                                               value={state.client.optional_geolocation}
                                                               onChange={value => setState({
                                                                   ...initialState,
                                                                   client: {...client, optional_geolocation: value}
                                                               })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <br/>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Text messages
                                                </ControlLabel>
                                                <ColorCheckbox className='no-left-padding'
                                                               label='Text messages enabled'
                                                               value={state.client.text_messages_enabled}
                                                               onChange={value => setState({
                                                                   ...initialState,
                                                                   client: {...client, text_messages_enabled: value}
                                                               })}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <br/>
                                            <Row><FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Translations
                                                </ControlLabel>
                                                <ColorCheckbox className='no-left-padding'
                                                               label='Translations enabled'
                                                               value={state.client.translations_enabled}
                                                               onChange={value => setState({
                                                                   ...initialState,
                                                                   client: {...client, translations_enabled: value}
                                                               })}
                                                />
                                            </FormGroup>
                                            </Row>
                                            {state.client.translations_enabled && <Row><Button bsSize="xsmall"
                                                                                               bsStyle="success"
                                                                                               disabled={clientTranslating}
                                                                                               onClick={() => translateClientData()}>
                                                Translate
                                                data</Button></Row>}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Address 1
                                                </ControlLabel>
                                                <FormControl
                                                    name="address_1"
                                                    placeholder="Address 1"
                                                    onChange={updateClientAttr}
                                                    value={client.address_1}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Address 2
                                                </ControlLabel>
                                                <FormControl
                                                    name="address_2"
                                                    placeholder="Last Name"
                                                    onChange={updateClientAttr}
                                                    value={client.address_2}
                                                />
                                            </FormGroup>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>

                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    City
                                                </ControlLabel>
                                                <FormControl
                                                    name="city"
                                                    placeholder="City"
                                                    onChange={updateClientAttr}
                                                    value={client.city}
                                                />
                                            </FormGroup>

                                        </Col>

                                        <Col md={6}>

                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    State
                                                </ControlLabel>
                                                <FormControl
                                                    name="state"
                                                    placeholder="State"
                                                    onChange={updateClientAttr}
                                                    value={client.state}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Zip
                                                </ControlLabel>
                                                <FormControl
                                                    name="zip"
                                                    placeholder="Zip"
                                                    onChange={updateClientAttr}
                                                    value={client.zip}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Country
                                                </ControlLabel>
                                                <FormControl
                                                    name="country"
                                                    placeholder="Country"
                                                    onChange={updateClientAttr}
                                                    value={client.country}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Phone
                                                </ControlLabel>
                                                <FormControl
                                                    name="phone_number"
                                                    placeholder="Phone"
                                                    onChange={updateClientAttr}
                                                    value={client.phone_number}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Email*
                                                </ControlLabel>
                                                <FormControl
                                                    name="email_address"
                                                    placeholder="Email"
                                                    onChange={updateClientAttr}
                                                    value={client.email_address}
                                                />
                                                <span className='font10 colorRed'>{emailErrorMsg}</span>
                                            </FormGroup>

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Special Info For Forms
                                                </ControlLabel>
                                                <FormControl
                                                    name="web_url"
                                                    placeholder="Keep license numbers or anything you may want to have on a document here"
                                                    onChange={updateClientAttr}
                                                    value={client.web_url}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Entity Id
                                                </ControlLabel>
                                                <FormControl
                                                    name="entity_id"
                                                    placeholder="Entity Id"
                                                    onChange={updateClientAttr}
                                                    value={client.entity_id || ''}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup bsSize="small">
                                                <ControlLabel>
                                                    Company Color
                                                </ControlLabel>
                                                <div
                                                    className={`c${colors.findIndex(c => c === client["embedded_background_color"])} 
                                                color-picker pointer visible bigger`}
                                                    onClick={() => setShowPicker(!showPicker)}/>
                                                {showPicker &&
                                                <CirclePicker width={400} colors={colors} onChange={(e) => {
                                                    updateClientColor(e.hex)
                                                    setShowPicker(false);
                                                }}/>}

                                            </FormGroup>
                                        </Col>
                                        <Col md={3} className="mt10">
                                            <ColorCheckbox value={client.disabled}
                                                           label="Disabled"
                                                           onChange={value => setState({
                                                               ...initialState,
                                                               client: {...client, disabled: value}
                                                           })}/>
                                        </Col>
                                        <Col md={6}>
                                            <br/>
                                            <Row>
                                                <FormGroup bsSize="small">
                                                    <ControlLabel>
                                                        Download Notifications
                                                        <OverlayTrigger placement="top"
                                                                        overlay={<Tooltip id="tooltip">
                                                                            <span>Enable this option to receive notifications about downloaded files</span>
                                                                        </Tooltip>}>
                                                            <FontAwesomeIcon icon={faInfoCircle} className="marginLeft10 font16"/>
                                                        </OverlayTrigger>
                                                    </ControlLabel>
                                                    <ColorCheckbox className='no-left-padding'
                                                                   label='Download Notifications'
                                                                   value={state.client.download_notifications}
                                                                   onChange={value => {
                                                                       setState({
                                                                           ...initialState,
                                                                           client: {
                                                                               ...client,
                                                                               download_notifications: value
                                                                           }
                                                                       })
                                                                       setDownloadNotificationsErrorMsg("")
                                                                   }}
                                                    />
                                                </FormGroup>
                                            </Row>
                                            {state.client.download_notifications &&
                                                <Row>
                                                    <FormControl
                                                        name="download_notifications_recipients"
                                                        placeholder="Notifications recipients"
                                                        className={`${!!downloadNotificationsErrorMsg ? "input-invalid" : "mb-15"}`}
                                                        onChange={e => {
                                                            setState({
                                                                ...initialState,
                                                                client: {...client, download_notifications_recipients: e.target.value}
                                                            })
                                                            setDownloadNotificationsErrorMsg("")
                                                        }}
                                                        value={client.download_notifications_recipients}
                                                    />
                                                    {!!downloadNotificationsErrorMsg && <div className='error-message mb-15'>{downloadNotificationsErrorMsg}</div>}
                                                </Row>}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12} className="text-right">
                                            <Button
                                                type="submit"
                                                bsSize="xsmall"
                                                bsStyle="success"
                                                disabled={invalidFieldValue}
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </Grid>}
                </Tab>
                <Tab eventKey={2} title="Users">
                    {clientsKey == 2 && <div className="top25">
                        {isDisableAllUsersModalOpen && <DisableAllUsersModal
                            show={isDisableAllUsersModalOpen}
                            onSave={() => {
                                handleDisableAllUsers();
                                setIsDisableAllUsersModalOpen(false);
                            }}
                            onCancel={() => {
                                setIsDisableAllUsersModalOpen(false)
                            }}
                        />}
                        <Row>
                            <Col md={6}>
                                <Button onClick={() => handleUserSelect({id: null, email: '', roles: []})}
                                        bsStyle="info"
                                className='mr-5'>
                                    New User
                                </Button>
                                <Button onClick={() => setIsDisableAllUsersModalOpen(true)}
                                        bsStyle="danger">
                                    Disable All Users
                                </Button>
                            </Col>
                            <Col md={3}>
                                {users && <span>Users: {users.length}</span>}
                            </Col>
                            <Col md={3}>
                                <form>
                                    <FormGroup>
                                        <ControlLabel>Max users count</ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Users count"
                                            onFocus={() => setShowBtn(true)}
                                            value={userCapacity}
                                            onChange={e => setUserCapacity(e.target.value)}
                                            style={{position: 'relative'}}
                                        />
                                    </FormGroup>
                                </form>
                                {showBtn && <div style={{position: 'absolute', right: 30, top: 32, cursor: 'pointer'}}
                                                 onClick={() => {
                                                     changeUserCapacity()
                                                     setShowBtn(false)
                                                 }}><FontAwesomeIcon icon={faCheck}/></div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <form className="top25">
                                    <FormGroup>
                                        <ControlLabel>Filter</ControlLabel>
                                        <FormControl
                                            type="text"
                                            placeholder="Filter employees"
                                            onChange={e => changeUserFilter(e.target.value)}
                                        />
                                    </FormGroup>
                                </form>
                                <div style={{height: isMobile ? "300px" : "600px", overflowY: "scroll"}}>
                                    <Nav
                                        bsStyle="pills"
                                        stacked
                                        onSelect={handleUserSelect}
                                    >
                                        {users && users.filter(userFilterRecord).map(u => (
                                            <NavItem key={u.id} eventKey={u}
                                                     active={user && user.id === u.id}
                                                     onClick={() => {
                                                         initialState.selectedUser = u
                                                         setState(initialState)
                                                     }}>{u.email}</NavItem>
                                        ))}
                                    </Nav>
                                </div>
                            </Col>
                            {user &&
                            <Col md={9}>
                                {renderUserEditor()}
                            </Col>
                            }
                        </Row>
                    </div>}
                </Tab>
                <Tab eventKey={3} title="Payments">
                    {clientsKey == 3 && (<><h3><strong>Payments</strong></h3>
                        <FormGroup bsSize="small">
                            <h4 className='text-primary top25'>
                                <ColorCheckbox value={payment.enableStripePayments}
                                               label='Enable Stripe Payments'
                                               className='vertical-align'
                                               onChange={() => setPayments({
                                                   ...payment,
                                                   enableStripePayments: !payment.enableStripePayments
                                               })}
                                />
                            </h4>
                        </FormGroup>
                        <hr/>
                        {payment.enableStripePayments && <div>
                            <Row>
                                <Col md={3}>
                                    <h4 style={{color: "#d9230f", marginLeft: 30}}>
                                        Transaction fee
                                    </h4>
                                    <div className='top15 left25'>
                                        <FormGroup className='vertical-align'>
                                            <Radio
                                                checked={payment.transactionFeeType === 'Amount'}
                                                onChange={() => setPayments({
                                                    ...payment,
                                                    transactionFeeType: 'Amount',
                                                    transactionFeeValue: 0
                                                })}
                                                name={'Amount'}
                                            >
                                                Amount
                                            </Radio>
                                            {
                                                payment.transactionFeeType === 'Amount' &&
                                                <FormGroup className='vertical-align bottom0'>
                                                    <ControlLabel className='small-margin'>$</ControlLabel>
                                                    <FormControl
                                                        name="amount"
                                                        type='number'
                                                        onChange={e => setPayments({
                                                            ...payment,
                                                            transactionFeeValue: e.target.value
                                                        })}
                                                        value={payment.transactionFeeValue}
                                                    />
                                                </FormGroup>
                                            }
                                        </FormGroup>
                                        <br/>
                                        <FormGroup className='vertical-align'>
                                            <Radio
                                                checked={payment.transactionFeeType === 'Percentage'}
                                                onChange={() => setPayments({
                                                    ...payment,
                                                    transactionFeeType: 'Percentage',
                                                    transactionFeeValue: 0
                                                })}
                                                name={'Percentage'}
                                            >
                                                Percentage
                                            </Radio>
                                            {
                                                payment.transactionFeeType === 'Percentage' &&
                                                <FormGroup className='vertical-align bottom0'>
                                                    <FormControl
                                                        name="percentage"
                                                        type='number'
                                                        onChange={(e) => setPayments({
                                                            ...payment,
                                                            transactionFeeValue: e.target.value.length !== 0 ? e.target.value : 0
                                                        })}
                                                        value={payment.transactionFeeValue}
                                                        className='small-margin'
                                                    />
                                                    <ControlLabel>%</ControlLabel>
                                                </FormGroup>
                                            }
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <Well className="flex-column-start">
                                        <p>Fee, which goes to the account of the application owner (to the platform
                                            account). It is added to the total invoice amount.</p>
                                        <ul>
                                            <li className="mt10">Amount - value determined in advance</li>
                                            <li>Percentage - value calculated from the invoice value</li>
                                        </ul>
                                    </Well>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup bsSize="small">
                                        <h4 className='text-primary top25'>
                                            <ColorCheckbox value={payment.customerFeeEnabled}
                                                           label='Apply Customer Fee'
                                                           className='vertical-align'
                                                           onChange={() => setPayments({
                                                               ...payment,
                                                               customerFeeEnabled: !payment.customerFeeEnabled
                                                           })}
                                            />
                                        </h4>
                                    </FormGroup>
                                    {
                                        payment.customerFeeEnabled &&
                                        <div className='top15 left25'>
                                            <FormGroup className='vertical-align'>
                                                <Radio
                                                    checked={payment.customerFeeType === 'Amount'}
                                                    onChange={() => setPayments({
                                                        ...payment,
                                                        customerFeeType: 'Amount',
                                                        customerFeeValue: 0
                                                    })}
                                                    name={'Customer_Amount'}
                                                >
                                                    Amount
                                                </Radio>
                                                {
                                                    payment.customerFeeType === 'Amount' &&
                                                    <FormGroup className='vertical-align bottom0'>
                                                        <ControlLabel className='small-margin'>$</ControlLabel>
                                                        <FormControl
                                                            name="amount"
                                                            type='number'
                                                            onChange={(e) => setPayments({
                                                                ...payment,
                                                                customerFeeValue: e.target.value
                                                            })}
                                                            value={payment.customerFeeValue}
                                                        />
                                                    </FormGroup>
                                                }
                                            </FormGroup>
                                            <br/>
                                            <FormGroup className='vertical-align'>
                                                <Radio
                                                    checked={payment.customerFeeType === 'Percentage'}
                                                    onChange={() => setPayments({
                                                        ...payment,
                                                        customerFeeType: 'Percentage',
                                                        customerFeeValue: 0
                                                    })}
                                                    name={'Customer_Percentage'}
                                                >
                                                    Percentage
                                                </Radio>
                                                {
                                                    payment.customerFeeType === 'Percentage' &&
                                                    <FormGroup className='vertical-align bottom0'>
                                                        <FormControl
                                                            name="percentage"
                                                            type='number'
                                                            onChange={e => setPayments({
                                                                ...payment,
                                                                customerFeeValue: e.target.value
                                                            })}
                                                            value={payment.customerFeeValue}
                                                            className='small-margin'
                                                        />
                                                        <ControlLabel>%</ControlLabel>
                                                    </FormGroup>
                                                }
                                            </FormGroup>
                                        </div>
                                    }
                                </Col>
                                <Col md={7} className="mt-20">
                                    <Well className="flex-column-start">
                                        <p>The fee that goes to the application client's account (to the connected
                                            account).
                                            It is added to the total invoice amount.</p>
                                        <ul>
                                            <li className="mt10">Amount - value determined in advance</li>
                                            <li>Percentage - value calculated from the invoice value</li>
                                        </ul>
                                    </Well>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <FormGroup bsSize="small">
                                        <h4 className='text-primary top25'>
                                            <ColorCheckbox value={payment.stripeProcessingFee}
                                                           label='Pass the Stripe processing fee on to customers'
                                                           className='vertical-align'
                                                           onChange={() => setPayments({
                                                               ...payment,
                                                               stripeProcessingFee: !payment.stripeProcessingFee
                                                           })}
                                            />
                                        </h4>
                                        <p className="left25">
                                            Stripe processing fee is <strong>2.9% + 30&#162;</strong>
                                        </p>
                                    </FormGroup>
                                </Col>
                                <Col md={7} className="mt-20">
                                    <Well className="flex-column-start">
                                        <p>You can pass your Stripe fees on to your customers by including the fee into
                                            the
                                            final charge amount.
                                            It is important to ensure that this action complies with any applicable laws
                                            that pertain to your business.
                                            If you are unsure whether such laws apply, consult with your legal
                                            counsel.</p>
                                    </Well>
                                </Col>
                            </Row>
                            <h4 className='text-primary top25'>
                                <ColorCheckbox value={payment.enableRefunds}
                                               label='Enable Refunds'
                                               className='vertical-align'
                                               onChange={() => setPayments({
                                                   ...payment,
                                                   enableRefunds: !payment.enableRefunds
                                               })}
                                />
                            </h4>
                        </div>}
                        <Button bsStyle="info" className="pull-right" onClick={() => updatePayments(client.id)}>
                            Save
                        </Button></>)}
                </Tab>
                <Tab eventKey={4} title='Transactions'>
                    {clientsKey == 4 &&
                    <ClientTransactions isAdminPaymentActive={state.isAdminPaymentActive} client={client}/>}
                </Tab>
                <Tab eventKey={5} title="Transfer Customer">
                    {clientsKey == 5 && <TransferCustomer clientsList={clientsList} clientId={client.id}/>}
                </Tab>
                <Tab eventKey={6} title="Licence">
                    {clientsKey == 6 && <Licences client={client.id}/>}
                </Tab>
            </Tabs>
        )
    }

    if (!isAdmin()) {
        console.warn("Non-admin tried to render Admin page or we're about to impersonate.")
        return null
    }

    const onUsersMapFiltersChange = (filters) => {
        setUsersMapFilterResource(filters)
    };

    const onChangeSelectedClients = (selectedClients) => {
        setUsersMapFilterSelectedClients(selectedClients)
    }

    return (
        <Grid fluid>
            <Helmet title="Admin"/>

            <Tabs defaultActiveKey={1}
                  onSelect={handleSelect}
                  activeKey={key}
                  animation={false}
                  id="noanim-tab-example"
            >
                <Tab eventKey={1} title="Clients">
                    {key === 1 && (<>
                        <PageHeader pageName="Admin" pageDetail={`- ${name}`}/>
                        <Row>
                            <Col xs={12}>
                                <Button onClick={refreshClientsList} bsStyle="success" className="mr-3">
                                    Refresh Clients List
                                </Button>
                                <Button onClick={() => editSelectedClient(0)} bsStyle="info">
                                    New Client
                                </Button>
                            </Col>
                        </Row>

                        <Row className='mb-35'>
                            <Col xs={isMobile ? 12 : 3}>
                                {<Tabs className="top25" defaultActiveKey={1} animation={false}
                                       activeKey={clientsTabsKey}
                                       onSelect={k => {
                                           setClientsTabsKey(k)
                                       }}>
                                    <Tab eventKey={1} title="Active"></Tab>
                                    {clientsList.find(c => c.disabled) && <Tab eventKey={2} title="Disabled"></Tab>}
                                </Tabs>}
                                <form>
                                    <FormGroup>
                                        <ControlLabel>Filter {clientsList && (clientsList.filter(c => c.disabled === (clientsTabsKey !== 1)).filter(filterRecord)).length} Clients</ControlLabel>
                                        <FormControl type="text" placeholder="Filter Users"
                                                     value={filterText}
                                                     onChange={e => changeFilterText(e.target.value)}
                                        />
                                    </FormGroup>
                                </form>
                                <div style={{height: isMobile ? '300px' : '600px', overflowY: 'scroll'}}>
                                    <Nav
                                        bsStyle="pills"
                                        stacked
                                        activeKey={clientId}
                                        onSelect={editSelectedClient}>
                                        {clientsList && clientsList.length > 0 && clientsList.filter(c => c.disabled === (clientsTabsKey !== 1)).filter(filterRecord).map(p => (
                                            <NavItem key={p.id} eventKey={p.id} onClick={() => {
                                                initialState.clientId = p.id
                                                initialState.employees = []
                                                setFilteredUser("")
                                                setState(initialState)
                                            }}>
                                                <div className="space-between">
                                                    <span>{p.name}</span>
                                                    <span style={{
                                                        fontSize: "10px",
                                                        color: "gray",
                                                        textWrap: "nowrap"
                                                    }}>{p.last_activity && dateFormatter(p.last_activity)}</span>
                                                </div>
                                            </NavItem>
                                        ))}
                                        {filterText && filterText.length > 0 && clientsList && clientsList.length > 0 && clientsList.length === 0 &&
                                        <p>No Client Matches With Your Filter</p>}
                                    </Nav>
                                </div>
                            </Col>
                            {!isMobile && <Col xs={9}>
                                {client && renderClientEditor()}
                            </Col>}
                        </Row>
                        {isMobile && client && <Row className="mb-35">
                            <Col xs={12}>
                                {renderClientEditor()}
                            </Col>
                        </Row>}
                    </>)}
                </Tab>
                <Tab eventKey={2} title="Activity">
                    {key === 2 && <ActivityList
                        activityList={activityList}
                        onRefresh={actions.fetchUserActivities}
                        onTruncate={actions.truncateActivities}
                    />}
                </Tab>
                <Tab eventKey={3} title="Issues">
                    {key === 3 && <IssueList
                        issueList={issueList}
                        deleteIssue={actions.deleteIssue}
                        fetchUserIssues={actions.fetchUserIssues}
                    />}
                </Tab>
                <Tab eventKey={4} title="Transactions">
                    {key === 4 && <AdminTransactions
                        isClientPaymentActive={isClientPaymentActive}
                    />}
                </Tab>
                <Tab eventKey={5} title="Users Map">
                    {key === 5 && (<>
                        <PageHeader pageName="Admin" pageDetail={`- ${name}`}/>

                        <Row className='mb-35'>
                            <Col xs={12}>
                                <Button className='mr-7' onClick={refreshUsersList} bsStyle="success">
                                    Refresh Users List
                                </Button>
                                <Button className="transactions_btn"
                                        onClick={() => setIsUsersMapModalOpen(true)}> Filter {" "}
                                    <FontAwesomeIcon icon="search" className="small-margin"/>
                                </Button>
                            </Col>
                        </Row>
                        {isUsersMapModalOpen &&
                            <LeadsFilterModal
                                title="Users Map Filter"
                                show={true}
                                onHide={() => {setIsUsersMapModalOpen(false); refreshUsersList()}}
                                closeButton={() => {setIsUsersMapModalOpen(false)}}
                            >
                            <UsersMapFilters
                                selectedClients={usersMapFilterSelectedClients}
                                onSearch={(e, callback) => actions.getClientsSearch(e, callback, true)}
                                resource={usersMapFilterResource}
                                onChangeFilters={onUsersMapFiltersChange}
                                onChangeSelectedClients={onChangeSelectedClients}
                            />
                            </LeadsFilterModal>
                        }
                    <Row>
                        <Col xs={isMobile ? 12 : xSizeScreen ? 4 : 3}>
                            <form>
                                <FormGroup>
                                    <ControlLabel className='space-between-end'>
                                        <div>Filter {usersList.length} {isLoggedIn ? "logged-in" : "logged-out"} users</div>
                                        <ButtonGroup bsSize="xsmall">
                                            <Button onClick={() => {
                                                setIsLoggedIn(true)
                                            }}
                                                    active={isLoggedIn}
                                                    className='font11'
                                                    style={{opacity: isLoggedIn ? 1 : 0.6, outline: 'none'}}>
                                                Logged-in Users
                                            </Button>
                                            <Button onClick={() => {
                                                setIsLoggedIn(false)
                                            }}
                                                    active={isLoggedIn === false}
                                                    className='font11'
                                                    style={{opacity: isLoggedIn === false ? 1 : 0.6, outline: 'none'}}>
                                                Logged-out Users
                                            </Button>
                                        </ButtonGroup>
                                    </ControlLabel>
                                    <FormControl type="text" placeholder="Filter Users"
                                                 defaultValue={usersListFilterText}
                                                 onChange={e => {
                                                     changeUserListFilterText(e.target.value)
                                                 }}
                                    />
                                </FormGroup>
                            </form>
                            <div style={{height: isMobile ? '300px' : '600px', overflowY: 'scroll'}}>
                                <Nav
                                    bsStyle="pills"
                                    stacked
                                    activeKey={selectedUserId}
                                    onSelect={selectUser.bind(this)}>
                                    {usersList && usersList.map(p => (
                                        <NavItem key={p.id}
                                                 eventKey={p.id}>
                                            <div className='space-between-end'>
                                                {`${p.first_name} ${p.last_name} (${p.client_name})`} {"  "}
                                                <span className='ml-7' onClick={() => setShowDevicedModal(true)}>
                                                 <OverlayTrigger placement="top" overlay={renderTooltip()}>
                                             <FontAwesomeIcon className="pointer" icon={faTabletAlt}/>
                                                 </OverlayTrigger>
                                        </span>
                                            </div>
                                        </NavItem>
                                    ))}
                                    {usersListFilterText.length > 0 && clientsList.length === 0 &&
                                    <p>No Client Matches With Your Filter</p>}
                                </Nav>
                            </div>
                        </Col>
                        {!isMobile && <Col xs={xSizeScreen ? 8 : 9}>
                            <UsersMap selectedUserId={selectedUserId} users={usersList}/>
                        </Col>}
                        <DevicesModal
                            show={showDevicesModal}
                            onHide={() => setShowDevicedModal(false)}
                            devices={devices}
                            onDelete={deleteDevice}
                        />
                        </Row>
                        {isMobile && <Row className="marginBottom10 padding-top-10">
                            <Col xs={12}>
                                <UsersMap selectedUserId={selectedUserId} users={usersList}/>
                            </Col>
                        </Row>}
                    </>)}
                </Tab>
                <Tab eventKey={6} title='Terms'>
                    {key === 6 && <Terms/>}
                </Tab>
                <Tab eventKey={7} title='Subscription Settings'>
                    {key === 7 && <SubscriptionSettings/>}
                </Tab>
                <Tab eventKey={8} title='Unfinished registrations'>
                    {key === 8 && <UnfinishedRegistration/>}
                </Tab>
                <Tab eventKey={9} title='New registrations'>
                    {key === 9 && <NewRegistration/>}
                </Tab>
                <Tab eventKey={10} title="Emails">
                    {key === 10 && <Emails/>}
                </Tab>
                <Tab eventKey={11} title='App Settings'>
                    {key === 11 && <AppSettings/>}
                </Tab>
                <Tab eventKey={12} title="Text Messages">
                    {key === 12 && <TextMessages/>}
                </Tab>
                <Tab eventKey={13} title='Deleted proposals'>
                    {key === 13 && <DeletedProposals/>}
                </Tab>
                {config.uat === 'true' && <Tab eventKey={14} title='Backups'>
                    {key === 14 && <Backups/>}
                </Tab>}
            </Tabs>
        </Grid>
    )
}

const mapStateToProps = state => ({
    name: state.auth.name,
    roles: state.auth.roles,
    userClientId: state.auth.client_id,
    clientsList: state.admin.clientsList.filter(
        p => p.name.toLowerCase().indexOf(state.admin.filterText.toLowerCase()) !== -1
    ),
    client: state.client.customerInfo,
    clientData: state.client,
    user: state.auth,
    currentUserId: state.auth.user_id
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(Actions, dispatch),
})

export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(NewAdminPage));
